import { isEqual, omit, range } from "lodash-es";
import { type SearchHit } from "services/searchHit";
import { derived, get, readonly, writable, type Updater } from "svelte/store";
import { getBildebankenHost, getNavigationBehavior } from "../config";
import { diffingDerivedStore } from "../utils/fns";
import { resetSelection } from "./SelectionService";
import {
  defaultSearchParams,
  type SearchFilter,
  type SearchParams,
  type SortParams,
} from "./params";
import { preloadItem } from "./queries/mimir";
import { pageToUrl, urlToPage } from "./url-mappers";
import { ingestKaleidoImagesToBildebanken, isKaleidoImage } from "services/kaleido";
import { glassPaneState } from "components/Plugin/GlassPane";
import { productFruitsInstance } from "services/productFruitsService";
import type { Folder } from "services/folders/types";

type ExternalImageSource = "Kaleido" | "Ntb";
type ImageSource = "Bildebanken" | ExternalImageSource;

export type Page =
  | ((
      | { type: "SEARCH" }
      | { type: "UPLOAD" }
      | {
          type: "DETAIL";
          itemSource?: ImageSource;
          itemId: string;
        }
      | {
          type: "EDIT";
          itemIds: string[];
          selectImageAfterEdit?: boolean;
          editOnlyMode?: boolean;
          highlightedFields?: string[];
        }
    ) & {
      searchParams: SearchParams;
      searchScrollPosition?: number;
      activeSearchResultSource?: ImageSource;
    })
  | { type: "LANDING_PAGE" };

export function isPageState(value: unknown): value is Page {
  if (!value || typeof value !== "object" || !("type" in value)) return false;
  switch (value.type) {
    case "UPLOAD":
    case "SEARCH":
      return "searchParams" in value;
    case "LANDING_PAGE":
      return true;
    case "DETAIL":
      return "itemId" in value;
    case "EDIT":
      return "itemIds" in value;
    default:
      return false;
  }
}

export const expandedFolders = writable<Set<string>>(new Set());
export const todaysFolderInitiallyOpen = writable<boolean>(true);
export const recentFolders = writable<{ folder: Folder; parent?: Folder }[]>([]);
export const currentFolder = writable<{ folder?: Folder; parent?: Folder }>({});

function PageState() {
  const { subscribe, update: _update, set } = writable<Page>(urlToPage(window.location.href));

  function updateHistory(page: Page, options = { pushState: true }) {
    get(productFruitsInstance)?.api.events.track(`${page.type.toLowerCase()}-navigated`);

    if (getNavigationBehavior() === "NONE") {
      return;
    }

    if (getNavigationBehavior() === "LOCATION") {
      if (options.pushState) {
        window.history.pushState(page, "", pageToUrl(page));
      } else {
        window.history.replaceState(page, "", pageToUrl(page));
      }
    } else {
      window.history.replaceState(page, "", pageToUrl(page));
    }
  }

  function update(updater: Updater<Page>, options = { pushState: true }) {
    return _update((page) => {
      const newPage = updater(page);
      if (!isEqual(newPage, page)) {
        updateHistory(newPage, options);
      }
      return newPage;
    });
  }

  function setAndPush(page: Page) {
    set(page);
    updateHistory(page);
  }

  // we add some side effects here
  return {
    subscribe,
    update,
    set,
    setAndPush,
  };
}

const _pageState = PageState();

export const pageState = readonly(_pageState);

export const searchText = derived(_pageState, (page) =>
  "searchParams" in page ? page.searchParams.query : "",
);
export const searchFilter = derived(_pageState, (page) =>
  "searchParams" in page ? page.searchParams.filter : defaultSearchParams.filter,
);
export const sortParams = derived(_pageState, (page) =>
  "searchParams" in page ? page.searchParams.sort : defaultSearchParams.sort,
);

export const searchParams = diffingDerivedStore(_pageState, (page) =>
  "searchParams" in page ? page.searchParams : defaultSearchParams,
);

export function onWindowPopState(event: PopStateEvent) {
  if (getNavigationBehavior() === "NONE") return;

  if (isPageState(event.state)) {
    event.preventDefault();
    _pageState.set(event.state);
  }
}

export function onClickAnchor(event: PointerEvent): void {
  const target = event.target;
  let link: HTMLAnchorElement | null = null;
  if (target instanceof HTMLAnchorElement) {
    link = target;
  } else if (target instanceof Element) {
    // in case user clicks on an icon inside a link tag for instance
    link = target.closest("a");
  }

  if (
    !link ||
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey ||
    event.defaultPrevented
  )
    return;

  if (
    link.href &&
    (!link.target || link.target === "_self") &&
    (link.origin === location.origin || link.origin === getBildebankenHost().origin)
  ) {
    event.preventDefault();
    const href = link.href;
    _pageState.update((oldPage) => {
      const newPage = urlToPage(href);

      if (
        oldPage.type === "EDIT" &&
        !confirm("Hvis du forlater redigeringssiden, vil du miste eventuelle endringer")
      ) {
        return oldPage;
      }

      if (
        (newPage.type === "DETAIL" || newPage.type === "EDIT" || newPage.type === "UPLOAD") &&
        "searchParams" in oldPage
      ) {
        newPage.searchParams = oldPage.searchParams;
      }

      // Remember currently open image source tab
      if (
        (newPage.type === "DETAIL" || newPage.type === "EDIT") &&
        "activeSearchResultSource" in oldPage
      ) {
        newPage.activeSearchResultSource = oldPage.activeSearchResultSource;
      }

      // Don't forget scroll position when navigating away from search page, in case you come back later
      if (
        (newPage.type === "DETAIL" || newPage.type === "EDIT") &&
        "searchScrollPosition" in oldPage
      ) {
        newPage.searchScrollPosition = oldPage.searchScrollPosition;
      }

      // Restore previously selected search results source
      if (
        newPage.type === "SEARCH" &&
        oldPage.type !== "SEARCH" &&
        oldPage.type !== "UPLOAD" &&
        "activeSearchResultSource" in oldPage &&
        isEqual(newPage.searchParams, oldPage.searchParams)
      ) {
        newPage.activeSearchResultSource = oldPage.activeSearchResultSource;
      }

      // Restore scroll position when coming back to search page.
      if (
        newPage.type === "SEARCH" &&
        oldPage.type !== "SEARCH" &&
        oldPage.type !== "UPLOAD" &&
        "searchScrollPosition" in oldPage &&
        // But only if we go back to the same search that we have stored the scroll-position for
        isEqual(newPage.searchParams, oldPage.searchParams)
      ) {
        newPage.searchScrollPosition = oldPage.searchScrollPosition;
      }

      return newPage;
    });
  }
}

export function showDetails(itemId: string, itemSource?: ImageSource): void {
  _pageState.update((page) => {
    return {
      ...extractPageParams(page),
      type: "DETAIL",
      itemId,
      itemSource: itemSource ?? "Bildebanken",
    };
  });
}

export function showDetailsKaleido(itemId: string) {
  showDetails(itemId, "Kaleido");
}

export function showDetailsNtb(itemId: string) {
  showDetails(itemId, "Ntb");
}

export function returnToDetails(itemIds: string[]): () => void {
  const item = itemIds.at(0);
  if (item) {
    return () => {
      showDetails(item);
    };
  } else {
    return returnToSearchPage;
  }
}

export function showDetailsForNextItem(allIds: string[]): void {
  _pageState.update(
    (page) => {
      if (page.type !== "DETAIL" || !allIds) {
        return page;
      }
      if (!allIds || allIds.length === 0) return page;
      const currentIndex = allIds.findIndex((id) => id === page.itemId);

      if (currentIndex === -1) return page;

      if (currentIndex === allIds.length - 1) {
        // TODO: here we should fetch more hits
        return page;
      }
      return { ...page, itemId: allIds[currentIndex + 1] };
    },
    { pushState: false },
  );
}

export function showDetailsForPreviousItem(allIds: string[]): void {
  _pageState.update(
    (page) => {
      if (page.type !== "DETAIL") {
        return page;
      }
      const currentIndex = allIds.findIndex((id) => id === page.itemId);

      if (currentIndex <= 0) return page;

      return { ...page, itemId: allIds[currentIndex - 1] };
    },
    { pushState: false },
  );
}

function extractPageParams(page: Page) {
  return {
    searchParams: "searchParams" in page ? page.searchParams : defaultSearchParams,
    searchScrollPosition: "searchScrollPosition" in page ? page.searchScrollPosition : undefined,
    activeSearchSource:
      "activeSearchResultSource" in page ? page.activeSearchResultSource : "Bildebanken",
  };
}

export function returnToSearchPage(): void {
  _pageState.update((page) => {
    return {
      ...extractPageParams(page),
      type: "SEARCH",
    };
  });
}

export function navigateToSearch(query: string): void {
  _pageState.update(() => {
    return {
      type: "SEARCH",
      searchParams: { ...defaultSearchParams, query },
      searchScrollPosition: undefined,
      activeSearchResultSource: "Bildebanken",
    };
  });
}

export function resetPage(): void {
  _pageState.setAndPush({ type: "LANDING_PAGE" });
}

export function getDetailLink(id: string): string {
  return new URL("/images/" + id, getBildebankenHost()).href;
}

export function getDetailLinkExternal(id: string, system: ExternalImageSource): string | undefined {
  if (id === undefined) {
    return undefined;
  } else {
    return `/images/${system.toLocaleLowerCase()}/${id}`;
  }
}

export function isAllImagesPage(page: Page): boolean {
  return page.type === "SEARCH" && !page.searchParams.owner;
}
export function isMyImagesPage(page: Page): boolean {
  return page.type === "SEARCH" && page.searchParams.owner === "current-user";
}

export function getAllImagesLink(page?: Page): string {
  let searchParams = defaultSearchParams;
  if (page && "searchParams" in page) {
    searchParams = omit(page.searchParams, "owner");
  }

  return pageToUrl({ type: "SEARCH", searchParams }).href;
}

export function getMyImagesLink(page?: Page): string {
  let searchParams = { ...defaultSearchParams };
  if (page && "searchParams" in page) {
    searchParams = { ...page.searchParams };
  }
  searchParams.owner = "current-user";

  return pageToUrl({ type: "SEARCH", searchParams }).href;
}

export function getCloseDetailsLink(page: Page): string {
  return pageToUrl({ ...extractPageParams(page), type: "SEARCH" }).href;
}

export async function showEdit(
  itemIds: string[],
  options: {
    selectImageAfterEdit?: boolean;
    editOnlyMode?: boolean;
    highlightedFields?: string[] | undefined;
  } = {
    selectImageAfterEdit: false,
    editOnlyMode: false,
    highlightedFields: undefined,
  },
) {
  let importedBildebankenIds: string[] | undefined = undefined;
  if (isKaleidoImage(itemIds[0])) {
    // Ingest Kaleido image to Bildebanken before editing
    try {
      glassPaneState.set({
        state: "VISIBLE",
        message: "Kopierer bilde(r) fra Kaleido til Bildebanken...",
      });
      importedBildebankenIds = await ingestKaleidoImagesToBildebanken(itemIds);
      glassPaneState.set({ state: "HIDDEN" });
    } catch (error) {
      glassPaneState.set({
        state: "SHOW_ERROR",
        message: `Bildet kunne ikke kopieres fra Kaleido. (${error})`,
      });
      throw new Error(`Failed to ingest Kaleido image to Bildebanken. (${error})`);
    }
  }
  _pageState.update((page) => {
    return {
      ...extractPageParams(page),
      type: "EDIT",
      itemIds: importedBildebankenIds ? importedBildebankenIds : itemIds,
      selectImageAfterEdit: options.selectImageAfterEdit,
      editOnlyMode: options.editOnlyMode,
      highlightedFields: options.highlightedFields,
    };
  });
}

export function showLanding(): void {
  _pageState.update((page) => ({ ...extractPageParams(page), type: "LANDING_PAGE" }));
}

export function showUpload(): void {
  _pageState.update((page) => ({ ...extractPageParams(page), type: "UPLOAD" }));
}

export function resetSearchAndFilters(): void {
  _pageState.update((page) => {
    if (page.type === "SEARCH") {
      return {
        ...page,
        searchParams: defaultSearchParams,
        searchScrollPosition: undefined,
      };
    }
    return page;
  });
}
export function updateSearchFilter<T extends keyof SearchFilter>(
  name: T,
  value?: SearchFilter[T],
): void {
  resetSelection();
  if (name === "folderId") {
    resetSearchAndFilters();
  }

  _pageState.update((page) => {
    let newState = { ...page };

    if (page.type === "LANDING_PAGE" && value) {
      newState = { type: "SEARCH", searchParams: defaultSearchParams };
    }

    if (newState.type === "SEARCH") {
      newState = {
        ...newState,
        searchParams: { ...newState.searchParams, filter: { ...newState.searchParams.filter } },
        searchScrollPosition: undefined,
      };

      if (name === "owner" && value === "current-user") {
        newState.searchParams.owner = value;
      } else if (value) {
        newState.searchParams.filter[name] = value;
      } else {
        delete newState.searchParams.filter[name];
        if (name === "folderId") {
          delete newState.searchParams.folderId;
        }
      }
    }
    return newState;
  });
}

export function updateFolderParam(folderId: string): void {
  resetSelection();

  _pageState.update((page) => {
    if (!("searchParams" in page)) return page;
    return {
      ...page,
      searchParams: {
        ...page.searchParams,
        folderId,
      },
      searchScrollPosition: undefined,
    };
  });
}

export function updateSortParams(params: Partial<SortParams>): void {
  resetSelection();

  _pageState.update((page) => {
    if (!("searchParams" in page)) return page;
    return {
      ...page,
      searchParams: {
        ...page.searchParams,
        sort: {
          ...page.searchParams.sort,
          ...params,
        },
      },
      searchScrollPosition: undefined,
    };
  });
}

export function searchByQuery(query: string): void {
  get(productFruitsInstance)?.api.events.track("search-triggered");
  _pageState.update((state) => {
    let searchParams: SearchParams;
    if ("searchParams" in state) {
      searchParams = state.searchParams;
    } else {
      searchParams = defaultSearchParams;
    }

    return {
      type: "SEARCH",
      searchParams: {
        ...searchParams,
        query,
      },
      searchScrollPosition: undefined,
    };
  });
}

export type navigationDirection = "forwards" | "backwards";

export function getNeighborIndexes(
  index: number,
  maxIndex: number,
  options: { direction: navigationDirection; amount: number },
) {
  const directionMultiplier = options.direction === "forwards" ? 1 : -1;
  const past = index - 1 * directionMultiplier;
  const next = index + 1 * directionMultiplier;
  const upcoming = range(next, next + options.amount * directionMultiplier, directionMultiplier);
  return [...upcoming, past].filter((index) => index >= 0 && index <= maxIndex);
}

export function preloadNeighbors(
  currentId: string,
  allHits: SearchHit[],
  options: { direction: navigationDirection; amount: number } = {
    direction: "forwards",
    amount: 2,
  },
): void {
  const currentIndex = allHits.findIndex((hit) => hit.id === currentId);
  const neighborIndexes = getNeighborIndexes(currentIndex, allHits.length - 1, options);
  const neighborItems = neighborIndexes.map((index) => allHits[index]);
  neighborItems.filter(Boolean).forEach((item: SearchHit) => preloadItem(item));
}

export function setActiveSearchResultSource(activeSearchResultSource: ImageSource) {
  _pageState.update(
    (page) => {
      if (page.type === "SEARCH")
        return { ...page, activeSearchResultSource: activeSearchResultSource };
      else return page;
    },
    { pushState: false },
  );
}

/**
 * Store scroll position for search page.
 *
 * Will be ignored if not on search page.
 */
export function setSearchScrollPosition(searchScrollPosition: number | undefined) {
  _pageState.update(
    (page) => {
      if (page.type === "SEARCH") return { ...page, searchScrollPosition };
      else return page;
    },
    { pushState: false },
  );
}

export function initPageFromUrl() {
  _pageState.set(urlToPage(window.location.href));
}

/**
 * Set page state without triggering change to history.
 * Should not be used to trigger navigation, only for initialization or remote-controlling plugin.
 */
export function setPage(page: Page): void {
  _pageState.set(page);
}

/**
 * Set page state and trigger change to history.
 *
 * Prefer using specific functions above, or anchor tags with correct links.
 * This is only intended for use in redirect flows, such as authentication.
 */
export function pushPage(page: Page): void {
  _pageState.setAndPush(page);
}
