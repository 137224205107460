import { setPreviewImageBoundries } from "components/Plugin/services/derivate";
import { upload } from "components/Upload/upload";
import {
  getBildebankenHost,
  getMountElement,
  setCloseCallback,
  setNavigationBehavior,
  setPluginHostAccount,
  setupAuthentication,
  type UserAccount,
} from "config";
import { pageState, searchByQuery, showDetails, showEdit, showLanding } from "state/page";
import { get } from "svelte/store";
import type { SelectedImage } from "../components/Plugin/imageTypes";
import {
  getSelectImageOnSave,
  setSystem,
  setUploadOptions,
  type UploadOptions,
} from "./plugin-customization";
import { getEnvironmentFromHostname } from "../utils/fns";

const THEME_SETTINGS = ["light", "dark", "user-preferred"];
export type ThemeSetting = "light" | "dark" | "user-preferred";

export let pluginVersion: string = "";

export type PluginOptions = {
  theme?: ThemeSetting;
  useCallbackAuth?: boolean;
  enableClose?: boolean;
  previewImageBoundries?: previewImageBoundries;
  upload?: UploadOptions;
  initialView?: ViewOptions;
  system: string;
};

export type ImageSources = "Bildebanken" | "Kaleido";

export type ViewOptions =
  | {
      page: "start";
    }
  | {
      page: "search";
      search: string;
    }
  | {
      page: "detail";
      imageId: string;
      search?: string;
    }
  | {
      page: "edit";
      imageIds: string[];
      highlightedFields?: string[];
      search?: string;
      source?: ImageSources | undefined;
    };

export type previewImageBoundries = {
  maxHeight: number;
  maxWidth: number;
};

export type StreamFile = File & {
  stream: ReadableStream<Uint8Array>;
};

interface CustomFulfilledResult<T> extends PromiseFulfilledResult<T> {
  status: "fulfilled";
}

type Message = {
  data:
    | { type: "send-version"; value: { version: string } }
    | { type: "set-view"; value: ViewOptions }
    | { type: "set-options"; value: Partial<PluginOptions> }
    | { type: "options-response"; value: PluginOptions }
    | {
        type: "upload-files-stream";
        value: StreamFile[];
      }
    | {
        type: "upload-files";
        value: FileList;
      }
    | {
        type: "token-response";
        value: {
          response?: { accessToken: string; account: UserAccount };
          transaction?: string;
          error?: unknown;
        };
      };
};

let port: MessagePort | undefined;
let host: string | undefined;

export function getPluginHost() {
  return host;
}

export function initMessageChannel(): Promise<void> {
  const initTime = performance.now();
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const duration = Math.floor(performance.now() - initTime);
      console.debug("[bildebanken] Init failed after " + duration + " ms");
      reject(Error("Timed out when initializing message channel with host application"));
      removeEventListener("message", init);
    }, 5000);

    function init(event: MessageEvent) {
      const duration = Math.floor(performance.now() - initTime);
      if (event.data === "init-message-port") {
        console.debug("[bildebanken] Init successful after " + duration + " ms");
        host = new URL(event.origin).hostname;
        port = event.ports[0];
        port?.start();
        port?.postMessage({ type: "connection-established", from: "bildebanken" });
        resolve();
        removeEventListener("message", init);
        clearTimeout(timeout);
      }
    }

    window.addEventListener("message", init);
  });
}

export function getTokenCallback(): Promise<string> {
  const transaction = crypto.randomUUID();

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      console.warn("Token request timed out", transaction);
      port?.removeEventListener("message", handleTokenResponse);
      reject(new Error("Timed out waiting for token response"));
    }, 3000);

    function handleTokenResponse(message: Message) {
      if (
        message.data.type === "token-response" &&
        message.data.value.transaction === transaction
      ) {
        const response = message.data.value.response;
        if (response && typeof response === "object") {
          resolve(response.accessToken);
          setPluginHostAccount(response.account);
        } else {
          console.warn("Token request failed", message.data.value.error);
          const reason = message.data.value.error ? `Reason: ${message.data.value.error}` : "";
          reject(new Error(`Empty token response from host application. ${reason}`));
        }
        port?.removeEventListener("message", handleTokenResponse);
        clearTimeout(timeout);
      }
    }

    port?.addEventListener("message", handleTokenResponse);
    port?.postMessage({ type: "get-token", value: { transaction } });
  });
}

export function selectImageCallback(image: SelectedImage) {
  port?.postMessage({ type: "image-selected", value: image }); // This single image selected variant will be deprecated in the future
  port?.postMessage({ type: "images-selected", value: [image] });
}

export function closePluginCallback() {
  port?.postMessage({ type: "close" });
}

export function updateCallback(image: SelectedImage) {
  port?.postMessage({ type: "image-update", value: image }); // This single image update variant will be deprecated in the future
  port?.postMessage({ type: "images-updated", value: [image] });
}

function validViewOptions(value: ViewOptions): boolean {
  // Search accepts empty string and string values. Edit and Detail accepts imageId.
  const validSearchOptions = value.page === "search" && (value.search === "" || !!value.search);
  const validDetailOptions = value.page === "detail" && !!value.imageId;
  const validEditOptions = value.page === "edit" && !!value.imageIds;
  const validStartOptions = value.page === "start";

  return validSearchOptions || validDetailOptions || validStartOptions || validEditOptions;
}

function createFileFromReadableStream(
  readableStream: ReadableStream,
  type: string,
  fileName: string,
): Promise<File> {
  const chunks: Uint8Array[] = [];
  const reader = readableStream.getReader();
  return new Promise<File>((resolve, reject) => {
    function read() {
      reader
        .read()
        .then(({ done, value }) => {
          if (done) {
            // All chunks have been read
            const blob = new Blob(chunks, { type: "application/octet-stream" });
            const file = new File([blob], fileName, { type });
            resolve(file);
          } else {
            // Accumulate chunks
            chunks.push(value);
            read();
          }
        })
        .catch(reject);
    }

    read();
  });
}

function createFileListFromStreamFiles(files: StreamFile[]) {
  return files.map((file) => createFileFromReadableStream(file.stream, file.type, file.name));
}

function isFulfilledResult<T>(result: PromiseSettledResult<T>): result is CustomFulfilledResult<T> {
  return result.status === "fulfilled";
}

const environment = getEnvironmentFromHostname(getBildebankenHost().hostname);

function handleMessage(message: Message) {
  switch (message.data.type) {
    case "send-version":
      console.log("Received version", message.data.value);
      pluginVersion = message.data.value.version;
      break;
    case "set-options":
      if (isPluginOptions(message.data.value)) {
        setPluginOptions(message.data.value);
      } else {
        console.warn("Invalid options payload", message.data.value);
      }
      break;
    case "upload-files": {
      const payload = message.data.value;
      if (payload && typeof payload === "object") {
        upload(payload);
        setTimeout(() => {
          console.debug("Page state", get(pageState).type);
        });
      } else {
        console.warn("Invalid upload file list payload", payload);
      }
      break;
    }
    case "upload-files-stream": {
      const payload = message.data.value;
      const filePromises = createFileListFromStreamFiles(payload);
      Promise.allSettled(filePromises).then((results) => {
        const files = results.filter(isFulfilledResult).map((result) => result.value);

        if (files) {
          upload(files);
          setTimeout(() => {
            console.debug("Page state", get(pageState).type);
          });
        } else {
          console.warn("Invalid upload file list payload", payload);
        }
      });
      break;
    }
    case "set-view":
      if (validViewOptions(message.data.value)) {
        setViewOptions(message.data.value, true);
      } else {
        console.warn("Invalid view options", message.data.value);
      }
      break;
    // token-response is handled in handleTokenResponse
    case "token-response":
      if (environment === "dev") console.warn("Unknown message type", message.data.type);
      break;
    default:
      console.warn("Unknown message type", message.data.type);
  }
}

export function listenForMessages() {
  port?.addEventListener("message", handleMessage);
}

export function stopListeningForMessages() {
  port?.removeEventListener("message", handleMessage);
}

function isPluginOptions(value: unknown): value is PluginOptions {
  if (!value || typeof value !== "object") {
    return false;
  }

  if (
    "previewImageBoundries" in value &&
    typeof value.previewImageBoundries !== "undefined" &&
    typeof value.previewImageBoundries !== "object"
  ) {
    return false;
  }

  if ("requiredFields" in value && typeof value.requiredFields !== "object") {
    return false;
  }

  if ("selectImageOnSave" in value && typeof value.selectImageOnSave !== "boolean") {
    return false;
  }

  if (
    "theme" in value &&
    typeof value.theme === "string" &&
    !THEME_SETTINGS.includes(value.theme)
  ) {
    return false;
  }

  if ("useCallbackAuth" in value && typeof value.useCallbackAuth !== "boolean") {
    return false;
  }

  if ("enableClose" in value && typeof value.enableClose !== "boolean") {
    return false;
  }

  return true;
}
export function getOptions(): Promise<PluginOptions> {
  return new Promise<PluginOptions>((resolve, reject) => {
    const timeout = setTimeout(() => {
      port?.removeEventListener("message", handleOptionsResponse);
      reject(new Error("Timed out waiting for options response"));
    }, 500);

    function handleOptionsResponse(message: Message) {
      if (message.data.type === "options-response") {
        clearTimeout(timeout);
        if (isPluginOptions(message.data.value)) {
          resolve(message.data.value);
          port?.removeEventListener("message", handleOptionsResponse);
        } else {
          return reject(Error("Invalid options-response"));
        }
      }
    }

    port?.addEventListener("message", handleOptionsResponse);
    port?.postMessage({ type: "get-options" });
  });
}

function setTheme(theme: ThemeSetting, root: HTMLElement) {
  switch (theme) {
    case "light":
      root.classList.add("bb-theme-light");
      root.classList.remove("bb-theme-dark");
      break;
    case "dark":
      root.classList.add("bb-theme-dark");
      root.classList.remove("bb-theme-light");
      break;
    case "user-preferred":
      root.classList.remove("bb-theme-light");
      root.classList.remove("bb-theme-dark");
      break;
  }
}

export function setPluginOptions(options: PluginOptions) {
  if (options.enableClose) {
    setCloseCallback(closePluginCallback);
  } else if (options.enableClose === false) {
    setCloseCallback(undefined);
  }

  if (options.previewImageBoundries) {
    setPreviewImageBoundries(options.previewImageBoundries);
  } else {
    setPreviewImageBoundries({ maxWidth: 300, maxHeight: 300 });
  }

  if (options.upload)
    setUploadOptions({
      requiredFields: options.upload.requiredFields,
      selectImageOnSave: options.upload.selectImageOnSave,
    });

  if (options.initialView && validViewOptions(options.initialView)) {
    setViewOptions(options.initialView);
  }

  if (options.useCallbackAuth) {
    setupAuthentication({ flow: "CALLBACK", account: {}, tokenCallback: getTokenCallback });
  } else {
    setupAuthentication({ flow: "POPUP" });
  }

  setNavigationBehavior("REPLACE_ALWAYS");

  if (options.theme) {
    const root = getMountElement();
    if (root) {
      setTheme(options.theme, root);
    } else {
      // Should never happen if app is correctly set up
      console.warn("Can not find root element to apply theme change to");
    }
  }

  if (options.system) {
    setSystem(options.system);
  }
}

export function setViewOptions(options: ViewOptions, editOnlyMode: boolean = false) {
  if (options.page !== "start" && (options.search || options.search === "")) {
    searchByQuery(options.search);
  }
  if (options.page === "detail") {
    showDetails(options.imageId);
  }
  if (options.page === "edit") {
    showEdit(options.imageIds, {
      selectImageAfterEdit: getSelectImageOnSave(),
      editOnlyMode: editOnlyMode,
      highlightedFields: options.highlightedFields || undefined,
    });
  }
  if (options.page === "start") {
    searchByQuery("");
    showLanding();
  }
}
